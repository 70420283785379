import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <div id="about">
      <div className="bg-gradient-to-b from-primary to-secondary h-8"></div>
      <div className="text-center bg-secondary">
        <div className="flex justify-center items-center flex-col sm:flex-row sm:gap-12">
          <div className="w-2/3 sm:w-1/4 hidden sm:block">
            <img
              src="/images/machine1.png"
              alt="Cotton Candy Machine"
              className="w-full sm:w-full mt-10"
            />
          </div>
          <div className="w-3/4 sm:w-2/5 p-4 sm:text-left ">
            <h2 className="text-5xl sm:text-7xl font-extrabold pt-4 pb-12 text-pink-400 text-center sm:text-right">
              ¿Quiénes somos?
            </h2>
            <div className="relative h-40 sm:w-[32rem] rounded-full p-4 my-20 ml-auto">
              <div className="absolute h-32 w-32 sm:h-40 sm:w-40 bg-white rounded-full -top-14 -left-6 sm:-top-16 sm:-left-8"></div>
              <div className="absolute h-32 w-32 sm:h-48 sm:w-48 bg-white rounded-full top-8 -left-14 sm:left-14 sm:-top-20"></div>
              <div className="absolute h-40 w-56 sm:h-44 sm:w-44 bg-white rounded-full sm:top-6 sm:-left-8"></div>
              <div className="absolute h-32 w-32 sm:h-48 sm:w-48 bg-white rounded-full -top-14 -right-6 sm:-top-20 sm:-right-10"></div>
              <div className="absolute h-32 w-32 sm:h-48 sm:w-48 bg-white rounded-full top-4 -right-8 sm:left-10 sm:top-16"></div>
              <div className="absolute h-32 w-32 sm:h-60 sm:w-60 bg-white rounded-full -bottom-28 right-20 sm:-bottom-24 sm:right-32"></div>
              <div className="absolute h-32 w-32 sm:h-48 sm:w-48 bg-white -top-20 left-20 rounded-full sm:left-36 sm:-top-16"></div>
              <div className="absolute h-32 w-32 sm:h-48 sm:w-48 bg-white -bottom-24 -left-4 rounded-full sm:left-72 sm:-top-20"></div>
              <div className="absolute h-40 w-40 sm:h-56 sm:w-56 bg-white rounded-full top-24 -right-6 sm:top-10 sm:-right-2"></div>
              <div className="absolute h-40 w-40 sm:h-48 sm:w-48 bg-white rounded-full top-24 -right-6 sm:top-14 sm:-right-28"></div>
              <div className="absolute inset-0 flex items-center text-pink-300 font-bold text-2xl sm:text-3xl text-center">
                <div className="mt-3 text-center sm:text-right">
                  <p className="text-2xl sm:text-3xl mt-4">
                    Pioneros en México en máquinas de algodón de
                    azúcar automatizadas. Ofrecemos experiencias que producen
                    emociones positivas.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="bg-white rounded-2xl shadow-md p-4">
              <p className="text-xl sm:text-3xl text-gray-700">
                Somos una empresa que ofrece un revolucionario servico
                automatizado de venta de algodón dulce en diferentes formas y
                deliciosos sabores, de manera muy vistosa y atractiva para el
                cliente, elaborado con un azúcar de alta calidad.
              </p>
            </div> */}
          </div>
          {/* Show on big screens */}
          <div className="w-2/3 sm:w-1/4 mt-4 mb-4 block sm:hidden">
            <img
              src="/images/machine1.png"
              alt="Cotton Candy Machine"
              className="w-full sm:w-full"
            />
          </div>
        </div>
        <Link to="/nosotros" className="flex justify-center pb-10 sm:pb-14">
          <button className="relative px-5 py-2 font-medium text-white group mt-10 transition-all duration-300 ease-out transform hover:shadow-xl hover:-translate-y-0.5 hover:scale-105">
            <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-pink-500 group-hover:bg-pink-700 group-hover:skew-x-12 rounded-xl"></span>
            <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-pink-700 group-hover:bg-pink-500 group-hover:-skew-x-12 rounded-xl"></span>
            <span className="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-pink-600 -rotate-12 rounded-xl"></span>
            <span className="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-pink-400 -rotate-12 rounded-xl "></span>
            <span className="relative text-2xl">Más Info</span>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default About;
